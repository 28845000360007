document.addEventListener('DOMContentLoaded', function() {
    var contribBar = document.getElementById('asset-contribute-bar');
    var contribBarShown = false;
    var adminBar = document.getElementById('asset-admin-bar');
    var localStorageSupported = __tnt.client.capabilities.supportsLocalStorage();

    if (contribBar && __tnt.isAuthor && localStorageSupported && (localStorage.getItem('tnt-hide-contrib-bar') === null || Date.now() > parseInt(localStorage.getItem('tnt-hide-contrib-bar')) + 86400000)) {
        contribBar.style.display = 'flex';
        contribBarShown = true;
        contribBar.querySelector('.asset-edit-close').addEventListener('click', function() {
            contribBar.style.display = '';
            localStorage.setItem('tnt-hide-contrib-bar', Date.now());
        });
    }

    if (!contribBarShown && TNCMS.User.isAdmin() && localStorageSupported && (localStorage.getItem('tnt-hide-admin-bar') === null || Date.now() > parseInt(localStorage.getItem('tnt-hide-admin-bar')) + 86400000)) {
        adminBar.style.display = 'flex';
        adminBar.querySelector('.asset-edit-close').addEventListener('click', function() {
            adminBar.style.display = '';
            localStorage.setItem('tnt-hide-admin-bar', Date.now());
        });
    }
});